import "./App.css";
import NavBar from "./Components/NavBar/NavBar";
import LandingPage from "./Pages/LandingPage/LandingPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PricingPage from "./Pages/PricingPage/PricingPage";
function App() {
  return (
    <div className="App">
      {/* <LandingPage/> */}
      <BrowserRouter>
      <NavBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route exact path="/pricing" element={<PricingPage />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
