import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./ImageCarousel.css";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import AOS from "aos";
import "aos/dist/aos.css";

import { EffectCoverflow, Pagination, Navigation } from "swiper";
const ImageCarousel = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="container-images">
      <div
        className="text-container"
        
      >
        <h2>Rooms:</h2>
        <p>
          Our rooms are designed with your comfort in mind. Each room is
          well-appointed and equipped with all the essentials to ensure a
          restful night's sleep.
        </p>
        <h2>Mess:</h2>
        <p>
          We understand the importance of good food to fuel your adventures,
          which is why we take pride in our delicious meals. Our in-house mess
          offers a variety of mouthwatering dishes that cater to different
          dietary preferences.
        </p>
      </div>
      <Swiper
        data-aos="zoom-in-up"
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src="/assets/hostel-front-view.jpeg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/hostel-room.jpeg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/room-photo.jpg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/3-seater-room.jpeg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/hostel-photo.jpeg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/food-provision.jpeg" alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="/assets/2-seater-room.jpeg" alt="slide_image" />
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <ion-icon name="arrow-back-outline"></ion-icon>
          </div>
          <div className="swiper-button-next slider-arrow">
            <ion-icon name="arrow-forward-outline"></ion-icon>
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default ImageCarousel;
