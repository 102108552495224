import React from "react";
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
// import { ReactComponent as Hamburger } from '../../assets/icons8-menu.svg'
// import { ReactComponent as Brand } from '../../assets/image 2.svg'
import "./NavBar.css";

const NavBar = () => {
  const navigate = useNavigate();
  const [showNavbar, setShowNavbar] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  useEffect(() => {
    // Add or remove a class to the body element based on the menu's active state
    document.body.classList.toggle("disable-scroll", showNavbar);
  }, [showNavbar]);
  return (
    <nav className="navbar">
      <div className="container">
        <div className="logo">
          <a href="www.sbboyshostel.in">
          <img src="/assets/logo.png" alt="" />
          </a>
        </div>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img src="/assets/menu.png" alt="" />
        </div>
        <div className={`nav-elements  ${showNavbar && "active"}`}>
          <ul>
            <li>
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              <NavLink to="/pricing">Pricing</NavLink>
            </li>
            {/* <li>
              <NavLink to="/safety">Safety</NavLink>
            </li>
            <li>
              <NavLink to="/support">Support</NavLink>
            </li> */}
            <li>
              <a href="https://wa.me/9303797324">
              <button id="signin">
              {/* <button id="signin" > */}
                Book on &nbsp;
                <img src="/assets/whatsapp.png" alt=""/>
              </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
