import React, { useEffect } from "react";
import "./Accomodation.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Accomodation = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);
  return (
    <div className="accomodation-container">
      <h1 data-aos="zoom-in-up">Accomodation Includes</h1>
      <div
        className="image-text-container"
        
      >
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/table.png" alt="" />
          <p>Hygenic and Tasty food</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/wifi.png" alt="" />
          <p>High Speed wifi</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/mirror.png" alt="" />
          <p>Mirrors</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/wadrobe.png" alt="" />
          <p>Spacious Wadrobe</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/bed.png" alt="" />
          <p>Comfortable Bed with Mattress</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/studytable.png" alt="" />
          <p>Table for study and lunch</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/tv.png" alt="" />
          <p>Common TV room</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/parking.png" alt="" />
          <p>Spacious Parking</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/room-cleaning2.png" alt="" />
          <p>Water Cooler RO</p>
        </div>
        <div className="facility-container" data-aos="zoom-in-up">
          <img src="/assets/roomclean.png" alt="" />
          <p>Room Cleaning</p>
        </div>
      </div>
    </div>
  );
};

export default Accomodation;
