import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      {/* <p>hello</p>
        <div className='top-info'>
        <div className='left-info'>
            <img src='/assets/logo.png' />
            
        </div>

        <div className='right-info'>

        </div>
        </div> */}

        <h1>SB Boys Hostel</h1>
        <p>Plot 47, near, railway crossing, Reti Mandi, Gamle Wale Puliya, <br/> Scheme 97, Hukma Khedi, Indore, Madhya Pradesh 452012</p>
        <br/>
        <p id="number"> <img src="/assets/call.png" alt=""/>
          777206884, 9303797324</p>
          <br/>
        <p>©️ 2023 SB Boys Hostel. All rights reserved</p>
     
    </div>
  );
};

export default Footer;
