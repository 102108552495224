import React from "react";
import './PricingPage.css'
const PricingPage = () => {
  return (
    <div className="pricing-page-container">
        
      <div className="room-container">
        <div className="bed-image-container">
        <img src="/assets/single-bed.svg" alt=""/>
        </div>
        <h2>Single Bed Room</h2>
        <h5>Pricing</h5>
        <h3>Rs. 9,999/month</h3>
        <p>*All rooms have same provisions <br/> *Extra Rs. 999 to be paid during registration</p>
      </div>

      <div className="room-container">
        <div className="bed-image-container">
        <img src="/assets/double-bed.svg" alt=""/>
        </div>
        <h2>Double Bed Room</h2>
        <h5>Pricing</h5>
        <h3>Rs. 6,999/month</h3>
        <p>*All rooms have same provisions <br/> *Extra Rs. 999 to be paid during registration</p>
     
      </div>

      <div className="room-container">
        <div className="bed-image-container">
        <img src="/assets/tripple-bed.svg" alt=""/>
        </div>
        <h2>Triple Bed Room</h2>
        <h5>Pricing</h5>
        <h3>Rs. 5,999/month</h3>
        <p>*All rooms have same provisions <br/> *Extra Rs. 999 to be paid during registration</p>
     
      </div>
    </div>
  );
};

export default PricingPage;
