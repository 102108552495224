import React, { useEffect } from "react";
import "./HeroSection.css";
import AOS from 'aos'
import 'aos/dist/aos.css'

const HeroSection = () => {
  const phoneNumber = "7772068884"; 

  const handleCallButtonClick = () => {
    const dialerUrl = `tel:${phoneNumber}`;
    window.location.href = dialerUrl;
  };
  useEffect(()=>{
    AOS.init({duration:2000})
  },[]);
  return (
    <div className="herosection-container">
      <div className="text-container">
        <h1 data-aos="fade-up">
          Welcome to Your Home <br /> Away from Home
        </h1>
        <p data-aos="fade-up">
          Looking for affordable and comfortable accommodation? Our hostel has
          you covered! Choose from our 1, 2 or 3 seater rooms, fully furnished
          with comfortable beds, study tables and chairs, and plenty of storage
          space. Ideal for students and professionals alike!
        </p>
        {/* <button>Confirm your seat now
            <img src="/assets/arrow.png" alt="" />
            
        </button> */}
        <button data-aos="fade-up" onClick={handleCallButtonClick}>
        <img src="/assets/call.png" alt="" />
          Contact Owner
          {/* <br/>
          7772068884 */}
        </button>
      </div>
      <div className="img-container">
        <img data-aos="zoom-in-up" src="/assets/hero-illus.svg" alt="" />
      </div>
    </div>
  );
};

export default HeroSection;
