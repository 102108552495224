import React, { useEffect } from "react";
import "./ContactUs.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ContactUs = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="contactus-container">
        <div className="form-container" >
          <h1>Contact us</h1>
          <p>Our Friendly team would love to hear from you</p>
          <form action="submit">
            <div className="form">
              <div className="row1-form">
                <div className="input-container">
                  <p>First Name</p>
                  <input type="text" placeholder="First name" required />
                </div>
                <div className="input-container">
                  <p>Last Name</p>
                  <input type="text" placeholder="Last name" required />
                </div>
              </div>
              <div className="input-container">
                <p>Email</p>
                <input
                  type="email"
                  placeholder="you@mail.com"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="input-container">
                <p>Phone number</p>
                <input
                  type="number"
                  placeholder="8623496217"
                  style={{ width: "100%" }}
                  required
                />
              </div>
              <div className="input-container">
                <p>Message</p>
                {/* <input type="text" placeholder="you@mail.com" style={{"width":"100%" }}/> */}
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="5"
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <button type="submit">Send message</button>
            </div>
          </form>
        </div>
        <div className="maps-container" >
          <iframe
            title="hostel-location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3681.7665945194913!2d75.82144261744385!3d22.662489400000027!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fc11fcbb49b5%3A0xe3bd5a17439dc453!2sS%20B%20BOYS%20HOSTEL!5e0!3m2!1sen!2sin!4v1685273067065!5m2!1sen!2sin"
            width="95%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen="yes"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
