import React from "react";
// import NavBar from "../../Components/NavBar/NavBar";
import "./LandingPage.css";
import HeroSection from "../../Components/HeroSection/HeroSection";
import Accomodation from "../../Components/Accomodation/Accomodation";
import ImageCarousel from "../../Components/ImageCarousel/ImageCarousel";
import ContactUs from "../../Components/ContactUs/ContactUs";
import Footer from "../../Components/Footer/Footer.js";

const LandingPage = () => {
  return (
    <div className="page-container">
      <HeroSection />
      {/* <hr/> */}
      <Accomodation />
      {/* <hr/> */}
      <ImageCarousel />
      {/* <hr/> */}
      <ContactUs />
      <Footer />
    </div>
  );
};

export default LandingPage;
